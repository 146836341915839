import { crsInstance } from '@/common/service/api/Axios';
import { LocalStorageTypes, getLS } from '@/common/service/storage';
import { TCRSData, TUser } from './domain';
import { createUserInfoData } from './creators';

enum CRS_ENDPOINTS {
    userUpdate = 'trackEvents',
    eventsTrack = 'trackEvents',
    commerceUpdateCart = 'trackEvents',
    commerceTrackPurchase = 'trackEvents',
    liveChat = 'trackEvents',
}

const sendPostEventCRS = (path: string, data: object) => {
    return crsInstance.post(path, data) as unknown as Promise<{
        status: number;
        data: object;
    }>;
};

export const getUser = () => {
    const user: TUser | undefined = getLS(LocalStorageTypes.LS_CONTACT_INFO);

    return user || ({} as TUser);
};

const res400Error = Promise.resolve({
    status: 400,
    data: { msg: 'Invalid email address' },
});

/* CRS events */

export const trackCRS = (eventType: string, data: object, newEmail?: string) => {
    const { email } = getUser();

    if (email || newEmail) {
        return sendPostEventCRS(CRS_ENDPOINTS.eventsTrack, {
            eventType,
            user: {
                email: newEmail || email,
            },
            data,
        });
    }
    return res400Error;
};

export const sendUserUpdateCRS = ({ email, dataFields }: { email: string; dataFields: object }) => {
    if (Object.keys(dataFields).length !== 0) {
        const userInfo = createUserInfoData();

        return sendPostEventCRS(CRS_ENDPOINTS.userUpdate, {
            eventType: 'user',
            user: {
                email,
                ...userInfo,
            },
            dataFields,
        });
    }
    return res400Error;
};

export const trackPurchaseCRS = (eventType: string, payload: object) => {
    const userInfo = createUserInfoData(true);

    if (userInfo.email) {
        return sendPostEventCRS(CRS_ENDPOINTS.commerceTrackPurchase, {
            eventType,
            user: {
                ...userInfo,
            },
            data: payload,
        });
    }
    return res400Error;
};

export const trackUpadteCartCRS = (eventType: string, data: TCRSData) => {
    const { email } = getUser();

    if (email) {
        return sendPostEventCRS(CRS_ENDPOINTS.commerceUpdateCart, {
            eventType,
            user: { email },
            data,
        });
    }
    return res400Error;
};

export const sendLiveChatCRS = (attributesEmail: string, firstName: string) => {
    const { email } = getUser();

    if (email !== attributesEmail) {
        trackSubscribeCRS(CRS_ENDPOINTS.commerceUpdateCart, attributesEmail);
        sendUserUpdateCRS({ email: attributesEmail, dataFields: { firstName } });
    }
};

export const trackSubscribeCRS = (
    eventType: string,
    newEmail?: string,
    user_info?: Record<string, string>,
    listId?: string
) => {
    const userInfo = user_info || createUserInfoData();

    if (newEmail) {
        return sendPostEventCRS(CRS_ENDPOINTS.eventsTrack, {
            eventType,
            user: {
                email: newEmail,
                ...userInfo,
            },
            ...(listId && {
                data: {
                    listId,
                },
            }),
        });
    }
    return res400Error;
};

export const trackCheckoutInfoCRS = (eventType: string, data: TCRSData) => {
    const userInfo = createUserInfoData(true);

    if (userInfo.email) {
        return sendPostEventCRS(CRS_ENDPOINTS.eventsTrack, {
            eventType,
            user: {
                ...userInfo,
            },
            data,
        });
    }
    return res400Error;
};
